var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"c-form--default",class:{ 'is-loading': _vm.loading },attrs:{"tag":"form","slim":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.showSuccess)?_c('div',{staticClass:"c-success-summary u-text-align--center",domProps:{"innerHTML":_vm._s(_vm.successMessage)}}):_vm._e(),_c('div',{staticClass:"c-form-content"},[_c('h3',{staticClass:"h1--md"},[_vm._v(_vm._s(_vm.model.header))]),_c('p',{staticClass:"u-font-size-dates"},[_vm._v(_vm._s(_vm.model.description))]),_c('div',{staticClass:"c-form-row c-form-column-container"},[_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.firstNameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-firstName"},domProps:{"textContent":_vm._s(_vm.model.firstNameLabel)}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.firstName),expression:"formModel.firstName"}],staticClass:"c-text-input",class:classes,attrs:{"id":"download-materials-firstName","placeholder":_vm.model.firstNameHint,"type":"text"},domProps:{"value":(_vm.formModel.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formModel, "firstName", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.lastNameLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-lastName"},domProps:{"textContent":_vm._s(_vm.model.lastNameLabel)}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.lastName),expression:"formModel.lastName"}],staticClass:"c-text-input",class:classes,attrs:{"id":"download-materials-lastName","placeholder":_vm.model.lastNameHint,"type":"text"},domProps:{"value":(_vm.formModel.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formModel, "lastName", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-form-row c-form-column-container"},[_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.emailLabel,"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-email"},domProps:{"textContent":_vm._s(_vm.model.emailLabel)}}),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.formModel.email),expression:"formModel.email",modifiers:{"lazy":true}}],staticClass:"c-text-input",class:classes,attrs:{"id":"download-materials-email","placeholder":_vm.model.emailHint,"type":"email"},domProps:{"value":(_vm.formModel.email)},on:{"change":function($event){return _vm.$set(_vm.formModel, "email", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.companyLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-company"},domProps:{"textContent":_vm._s(_vm.model.companyLabel)}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.company),expression:"formModel.company"}],staticClass:"c-text-input",class:classes,attrs:{"id":"download-materials-company","placeholder":_vm.model.companyHint,"type":"text"},domProps:{"value":(_vm.formModel.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formModel, "company", $event.target.value)}}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-form-row c-form-column-container"},[_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.countryLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-country"},domProps:{"textContent":_vm._s(_vm.model.countryLabel)}}),_c('Dropdown',{staticClass:"c-form-dropdown",class:classes,attrs:{"id":"download-materials-country","hint":_vm.model.countryHint,"items":{highlighted: _vm.model.highlightedCountries, main: _vm.model.countries}},model:{value:(_vm.formModel.country),callback:function ($$v) {_vm.$set(_vm.formModel, "country", $$v)},expression:"formModel.country"}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.industryLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-industry"},domProps:{"textContent":_vm._s(_vm.model.industryLabel)}}),_c('Dropdown',{staticClass:"c-form-dropdown",class:classes,attrs:{"id":"download-materials-industry","hint":_vm.model.industryHint,"items":{group: _vm.model.industries}},model:{value:(_vm.formModel.industry),callback:function ($$v) {_vm.$set(_vm.formModel, "industry", $$v)},expression:"formModel.industry"}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-form-row c-form-column-container"},[_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.titleLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-title"},domProps:{"textContent":_vm._s(_vm.model.titleLabel)}}),_c('Dropdown',{staticClass:"c-form-dropdown",class:classes,attrs:{"id":"download-materials-title","hint":_vm.model.titleHint,"items":{group: _vm.model.titles}},model:{value:(_vm.formModel.title),callback:function ($$v) {_vm.$set(_vm.formModel, "title", $$v)},expression:"formModel.title"}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('ValidationProvider',{staticClass:"c-form-column",attrs:{"name":_vm.model.downloadCategoryLabel,"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"c-label--s",attrs:{"for":"download-materials-category"},domProps:{"textContent":_vm._s(_vm.model.downloadCategoryLabel)}}),_c('Dropdown',{staticClass:"c-form-dropdown",class:classes,attrs:{"id":"download-materials-category","hint":_vm.model.downloadCategoryHint,"items":{group: _vm.model.downloadCategories}},model:{value:(_vm.formModel.category),callback:function ($$v) {_vm.$set(_vm.formModel, "category", $$v)},expression:"formModel.category"}}),(errors.length)?_c('span',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"c-disclaimer u-mt--l u-font-size-dates",domProps:{"innerHTML":_vm._s(_vm.model.disclaimerHtml)}}),_c('div',{staticClass:"c-form-row"},[(!_vm.model.hidePermissionCheckboxes)?_c('ValidationProvider',{staticClass:"c-checkbox",attrs:{"rules":"required","name":_vm.model.privacyPolicyLabel,"tag":"label","for":"download-materials-terms-of-use"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.privacyPolicy),expression:"formModel.privacyPolicy"}],staticClass:"c-checkbox__input",class:classes,attrs:{"id":"download-materials-terms-of-use","value":"terms","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formModel.privacyPolicy)?_vm._i(_vm.formModel.privacyPolicy,"terms")>-1:(_vm.formModel.privacyPolicy)},on:{"change":function($event){var $$a=_vm.formModel.privacyPolicy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="terms",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formModel, "privacyPolicy", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formModel, "privacyPolicy", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formModel, "privacyPolicy", $$c)}}}}),_c('span',{staticClass:"c-checkbox__label u-font-size-dates",domProps:{"innerHTML":_vm._s(_vm.model.termsHtml)}}),_c('span',{staticClass:"c-checkbox-icon"}),(errors.length)?_c('div',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,4171470946)}):_vm._e(),(!_vm.model.hidePermissionCheckboxes)?_c('ValidationProvider',{staticClass:"c-checkbox",attrs:{"rules":"required","name":_vm.model.acceptSubscriptionLabel,"tag":"label","for":"download-materials-newsletter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formModel.acceptSubscription),expression:"formModel.acceptSubscription"}],staticClass:"c-checkbox__input",class:classes,attrs:{"id":"download-materials-newsletter","value":"subscription","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formModel.acceptSubscription)?_vm._i(_vm.formModel.acceptSubscription,"subscription")>-1:(_vm.formModel.acceptSubscription)},on:{"change":function($event){var $$a=_vm.formModel.acceptSubscription,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="subscription",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formModel, "acceptSubscription", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formModel, "acceptSubscription", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formModel, "acceptSubscription", $$c)}}}}),_c('span',{staticClass:"c-checkbox__label u-font-size-dates",domProps:{"innerHTML":_vm._s(_vm.model.subscriptionHtml)}}),_c('span',{staticClass:"c-checkbox-icon"}),(errors.length)?_c('div',{staticClass:"c-error-message u-text-error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1774000001)}):_vm._e()],1),(_vm.errorMessage)?_c('p',{staticClass:"u-text-error u-mt--l u-font-size-dates",domProps:{"textContent":_vm._s(_vm.errorMessage)}}):_vm._e(),_c('div',{staticClass:"c-form-row"},[_c('input',{staticClass:"c-button",attrs:{"type":"submit"},domProps:{"value":_vm.model.sendButtonText}})])]),(_vm.loading)?_c('CssLoader',{staticClass:"css-loader"}):_vm._e(),_c('recaptcha',{ref:"recaptchaform",attrs:{"recaptcha-key":_vm.model.recaptchaKey,"recaptcha-base-url":_vm.model.recaptchaBaseUrl},on:{"submit":_vm.submitForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
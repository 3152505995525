<template>
  <div>
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="token"
    >
    <recaptcha
      ref="recaptcha"
      :recaptcha-key="model.recaptchaKey"
      :recaptcha-base-url="model.recaptchaBaseUrl"
      @submit="recaptchaSubmit"
    />
  </div>
</template>

<script>
import Recaptcha from '@/Redesign/Components/Recaptcha.vue'
import { ref, onMounted } from '@vue/composition-api'

export default {
  components: {
    Recaptcha
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const token = ref('')
    const recaptcha = ref(null)

    const recaptchaSubmit = async (tokenValue) => {
      token.value = tokenValue

      const form = document.getElementById(props.model.formGuid)
      const submitButton = form.querySelector('[type=submit]')
      submitButton.click()
    }

    onMounted(() => {
      const forms = window.epi && window.epi.EPiServer && window.epi.EPiServer.Forms
      if (!forms) {
        return
      }

      $.extend(true, forms, {
        Validators: {
          ' Modules.Features.FormElements.Components.InvisibleRecaptchaElementBlockComponent.InvisibleRecaptchaValidator': (_, fieldValue) => {
            const isValid = !!fieldValue
            if (!isValid && recaptcha.value) {
              recaptcha.value.verify()
            }

            return { isValid }
          }
        }
      })
    })

    return {
      token,
      recaptcha,
      recaptchaSubmit
    }
  }
}
</script>

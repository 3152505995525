<template>
  <ValidationObserver
    ref="observer"
    tag="form"
    slim
    :class="{ 'is-loading': loading }"
    @submit.prevent="onSubmit"
  >
    <div v-if="showSuccess">
      <div
        class="subscriber-form__success"
        v-html="successHtml"
      />
    </div>
    <template v-else>
      <h3 class="subscriber-form__headline">{{ model.headline }}</h3>

      <ValidationProvider
        v-slot="{ errors, classes }"
        :name="model.emailPlaceholder"
        rules="required|email"
        tag="div"
        class="subscriber-form__email"
      >
        <input
          v-model.lazy="formModel.email"
          :class="classes"
          :placeholder="model.emailPlaceholder"
          type="email"
        >
        <span
          v-if="errors.length"
          class="subscriber-form__error"
        >
          {{ errors[0] }}
        </span>
      </ValidationProvider>

      <div
        class="subscriber-form__disclaimer"
        v-html="disclaimerHtml"
      />

      <ValidationProvider
        v-if="!hidePermissionCheckboxes"
        v-slot="{ errors, classes }"
        rules="requiredPrivacyPolicy"
        name="Privacy policy"
        class="subscriber-form__lists"
        tag="div"
      >
        <label
          for="terms-of-use"
          class="subscriber-form__list subscriber-form__list--wide"
        >
          <input
            id="terms-of-use"
            v-model="formModel.privacyPolicy"
            value="terms"
            :class="classes"
            type="checkbox"
          >
          <span
            class="u-font-size-dates"
            v-html="termsHtml"
          />
        </label>

        <label
          for="newsletter"
          class="subscriber-form__list subscriber-form__list--wide"
        >
          <input
            id="newsletter"
            v-model="formModel.privacyPolicy"
            value="newsletter"
            type="checkbox"
          >
          <span
            class="u-font-size-dates"
            v-html="subscriptionHtml"
          />
        </label>
        <div
          v-if="errors.length"
          class="subscriber-form__list subscriber-form__error"
        >
          {{ errors[0] }}
        </div>
      </ValidationProvider>

      <p
        v-if="errorMessage"
        class="u-text-error u-mt--l"
        v-text="errorMessage"
      />

      <input
        type="submit"
        :value="model.subscribeButtonText"
        class="subscriber-form__button"
      >
    </template>

    <CssLoader
      v-if="loading"
      class="css-loader"
    />

    <recaptcha
      ref="recaptchaform"
      :recaptcha-key="model.recaptchaKey"
      :recaptcha-base-url="model.recaptchaBaseUrl"
      @submit="submitForm"
    />
  </ValidationObserver>
</template>

<script>
import { ref, onMounted, reactive } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Recaptcha from '@/Redesign/Components/Recaptcha.vue'
import CssLoader from '@/Redesign/Components/CssLoader.vue'
import { requiredAtLeaseOneCheckbox, requiredBothCheckboxes } from '@/Redesign/Helpers/VeeValidate'
import api from '@/Redesign/Lib/api'
import { getPageUrl } from "@/Redesign/Helpers/url";

// TODO: Add proper translation for messages
extend('required', required)
extend('requiredMailingList', requiredAtLeaseOneCheckbox)
extend('requiredPrivacyPolicy', requiredBothCheckboxes)
extend('email', email)

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    CssLoader,
    Recaptcha
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    successHtml: {
      type: String,
      default: ''
    },
    disclaimerHtml: {
      type: String,
      default: ''
    },
    subscriptionHtml: {
      type: String,
      default: ''
    },
    termsHtml: {
      type: String,
      default: ''
    },
    hidePermissionCheckboxes: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const observer = ref(null)
    const formModel = reactive({
      privacyPolicy: [],
      email: null
    })
    const showSuccess = ref(false)
    const errorMessage = ref(null)
    const loading = ref(false)
    const recaptchaform = ref(null)

    const onSubmit = async () => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        // ABORT!!
      } else {
        recaptchaform.value.verify()
      }
    }

    const submitForm = async (token) => {
      const isValid = await observer.value.validate()
      if (!isValid) {
        return
      }

      errorMessage.value = null
      loading.value = true

      api.blogSubscribe({
        email: formModel.email,
        acceptTerms: formModel.privacyPolicy.some(x => x === 'terms'),
        acceptSubscription: true,
        version: props.model.version,
        language: props.model.locale,
        pageUrl: getPageUrl(),
      }, token)
        .then(() => {
          showSuccess.value = true
        })
        .catch((error) => {
          errorMessage.value = error
        })
        .then(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      setTimeout(() => {
        observer.value.reset() // FIX: IE11 invalid field on component init
      }, 0)
    })

    return {
      observer,
      recaptchaform,
      formModel,
      showSuccess,
      onSubmit,
      submitForm,
      loading,
      errorMessage
    }
  }
}
</script>
